import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { THEME } from "@/components/@types";
import { onMounted, ref, reactive, watch, toRef } from 'vue';
import { BaseButton, BaseIcon, BaseInput } from "@/components/@base";
import { useClientStore } from "@/store/client";
import { storeToRefs } from "pinia";
import { INPUT_MODE } from "@/components/@base/@types";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScriptPhoneStep',
    props: {
        modelValue: { default: null },
        required: { type: Boolean, default: false },
        theme: { default: THEME.light }
    },
    emits: ["update:modelValue", "confirmEntry"],
    setup(__props, { emit: __emit }) {
        const { client } = storeToRefs(useClientStore());
        const props = __props;
        const emits = __emit;
        const modelValue = toRef(props, 'modelValue');
        const phones = ref([null]);
        const temp = ref([]);
        const data = reactive({ ...phones.value });
        const dataChanges = ref(false);
        function setValue(evt, idx) {
            console.log('evt', evt);
            data[idx] = evt;
        }
        function addphone() {
            for (let prop in data) {
                phones.value[prop] = data[prop];
            }
            phones.value.push(null);
        }
        function deletePhone(idx) {
            delete data[idx];
            phones.value.splice(idx, 1);
            let i = 0;
            for (let prop in data) {
                phones.value[i] = data[prop];
                i++;
            }
        }
        function handleEnter() {
            emits('confirmEntry');
        }
        watch(() => data, () => {
            let i = 0;
            temp.value = [];
            for (let prop in data) {
                temp.value[i] = data[prop];
                i++;
            }
            emits('update:modelValue', temp.value.filter((el) => el));
            if (client.value?.phone?.value)
                client.value.phone.value = [...temp.value.filter((el) => el)];
            dataChanges.value = true;
        }, { deep: true });
        watch(() => phones.value, (newVal) => {
            for (let prop in data) {
                delete data[prop];
            }
            newVal.forEach((el, idx) => {
                data[idx] = el;
            });
        }, { deep: true });
        watch(() => client.value?.phone?.value, (newVal) => {
            if (!dataChanges.value) {
                phones.value = Array.isArray(newVal) ? [...newVal] : [newVal];
            }
            dataChanges.value = false;
        }, { deep: true });
        onMounted(() => {
            if (props.modelValue) {
                phones.value = [];
                if (typeof props.modelValue === 'string') {
                    props.modelValue.replace(' ', '').split(',').forEach((el) => {
                        phones.value.push(el);
                    });
                }
                else {
                    props.modelValue.forEach((el) => {
                        phones.value.push(el);
                    });
                }
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phones.value, (phone, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: phone + '' + idx,
                        class: "email-container"
                    }, [
                        _createVNode(_unref(BaseInput), {
                            "model-value": data[idx],
                            theme: props.theme,
                            required: props.required,
                            mode: _unref(INPUT_MODE).phone,
                            width: "250px",
                            "onUpdate:modelValue": ($event) => (setValue($event, idx)),
                            onConfirmEntry: handleEnter
                        }, null, 8, ["model-value", "theme", "required", "mode", "onUpdate:modelValue"]),
                        (idx !== 0)
                            ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                                key: 0,
                                name: "delete",
                                size: "24",
                                onClick: ($event) => (deletePhone(idx))
                            }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                    ]));
                }), 128)),
                _createVNode(_unref(BaseButton), {
                    label: "Добавить",
                    theme: props.theme,
                    onClick: addphone
                }, null, 8, ["theme"])
            ]));
        };
    }
});
