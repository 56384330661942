import { defineStore } from 'pinia';
import { useScriptStore } from '@/store/script';
import { useFormattedScripts } from '@/hooks/useFormattedScripts';
export const useClientStore = defineStore('client', {
    state: () => {
        return {
            client: null,
            linkedEntity: null,
            callback: {
                text: '',
                person: null,
                date: null,
                theme: '',
                phone: ''
            },
            infoMode: 'own',
            knowledgeBase: null,
            kbStartPage: null,
        };
    },
    actions: {
        initClient() {
            const { clientData, knowledgeBaseId, knowledgeBasePageId } = useScriptStore();
            const { clientContactData } = useScriptStore();
            this.knowledgeBase = knowledgeBaseId;
            this.kbStartPage = knowledgeBasePageId;
            console.log('update', knowledgeBaseId);
            this.client = {
                id: clientData.id || '',
                lead: 7677485,
                name: {
                    value: this.getValueElement(clientData.name) || '',
                    id: clientData.name || ''
                },
                patronymic: {
                    id: clientData.patronymic || '',
                    value: this.getValueElement(clientData.patronymic) || ''
                },
                surname: {
                    id: clientData.surname || '',
                    value: this.getValueElement(clientData.surname) || ''
                },
                city: {
                    id: clientData.city || '',
                    value: this.getValueElement(clientData.city) || ''
                },
                education: {
                    id: clientData.education || '',
                    value: this.getValueElement(clientData.education) || ''
                },
                land: {
                    id: clientData.land || '',
                    value: this.getValueElement(clientData.land) || ''
                },
                phone: {
                    id: clientData.phone || '',
                    value: this.getValueElement(clientData.phone) || []
                },
                mail: {
                    id: clientData.mail || '',
                    value: this.getValueElement(clientData.mail) || []
                },
                relatives: this.initRelatives().length > 0 ? this.initRelatives() : [],
            };
            if (clientContactData) {
                this.linkedEntity = {
                    id: clientContactData?.id || '',
                    lead: 7677485,
                    name: {
                        value: this.getValueElement(clientContactData?.name) || '',
                        id: clientContactData?.name || ''
                    },
                    patronymic: {
                        id: clientContactData?.patronymic || '',
                        value: this.getValueElement(clientContactData?.patronymic) || ''
                    },
                    surname: {
                        id: clientContactData?.surname || '',
                        value: this.getValueElement(clientContactData?.surname) || ''
                    },
                    city: {
                        id: clientContactData?.city || '',
                        value: this.getValueElement(clientContactData?.city) || ''
                    },
                    education: {
                        id: clientContactData?.education || '',
                        value: this.getValueElement(clientContactData?.education) || ''
                    },
                    land: {
                        id: clientContactData?.land || '',
                        value: this.getValueElement(clientContactData?.land) || ''
                    },
                    phone: {
                        id: clientContactData?.phone || '',
                        value: this.getValueElement(clientContactData?.phone) || []
                    },
                    mail: {
                        id: clientContactData?.mail || '',
                        value: this.getValueElement(clientContactData?.mail) || []
                    },
                    relatives: this.initRelatives(true).length > 0 ? this.initRelatives(true) : [],
                };
            }
            else {
                this.linkedEntity = null;
            }
        },
        getElementById(id) {
            const { elements } = useScriptStore();
            return elements?.find(element => element.id === id);
        },
        getValueElement(elementId) {
            const { processValueFromJSON } = useFormattedScripts();
            const element = this.getElementById(elementId);
            if (element) {
                try {
                    return processValueFromJSON(element.value);
                }
                catch (error) {
                    return element.value;
                }
            }
            return undefined;
        },
        buildRelative({ type, fio, phone, mail }) {
            const elementFio = this.getElementById(fio);
            const elementPhone = this.getElementById(phone);
            const elementMail = this.getElementById(mail);
            return {
                type,
                fio: { id: fio, value: elementFio ? elementFio.value : '' },
                phone: { id: phone, value: elementPhone ? elementPhone.value : '' },
                mail: { id: mail, value: elementMail ? elementMail.value : '' }
            };
        },
        initRelatives(linked = false) {
            const relativesData = [
                this.getDataByTypeRelative('1', linked),
                this.getDataByTypeRelative('2', linked)
            ];
            return relativesData.map(data => this.buildRelative(data)).filter(relative => relative.fio.value);
        },
        getDataByTypeRelative(type, linked = false) {
            const { clientData } = useScriptStore();
            const { clientContactData } = useScriptStore();
            switch (type) {
                case '1':
                    return {
                        type: '1',
                        fio: linked ? clientContactData?.fatherFio : clientData.fatherFio,
                        phone: linked ? clientContactData?.fatherPhone : clientData.fatherPhone,
                        mail: linked ? clientContactData?.fatherMail : clientData.fatherMail,
                    };
                case '2':
                    return {
                        type: '2',
                        fio: linked ? clientContactData?.motherFio : clientData.motherFio,
                        phone: linked ? clientContactData?.motherPhone : clientData.motherPhone,
                        mail: linked ? clientContactData?.motherMail : clientData.motherMail,
                    };
            }
        },
        setCallbackData(data) {
            this.callback = data;
        }
    }
});
