import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = ["onClick"];
const _hoisted_4 = { class: "base-table__th" };
const _hoisted_5 = {
    key: 0,
    class: "base-table__sort"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
    key: 0,
    class: "base-table__pagination"
};
const _hoisted_8 = {
    key: 1,
    class: "base-table__loader-container"
};
import { THEME } from "@/libs/enum";
const __default__ = { name: 'BaseTable' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        columns: { default: null },
        rows: { default: null },
        loading: { type: Boolean, default: false },
        paginated: { type: Boolean, default: false },
        totalText: { default: 'Всего' },
        interval: { default: 20 },
        total: { default: 0 },
        pagePagination: { default: 1 },
        theme: { default: THEME.dark },
        alwaysShowHead: { type: Boolean, default: false }
    },
    emits: ["reachEnd", "change-page", "update:pagePagination", "click:row", "click:th"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const changePage = ({ offset, limit }) => {
            emits('change-page', { offset, limit });
        };
        const updatePagePagination = (page) => {
            emits('update:pagePagination', page);
        };
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar");
            const _component_base_pagination = _resolveComponent("base-pagination");
            const _component_base_loader = _resolveComponent("base-loader");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["base-table", props.theme])
            }, [
                _createVNode(_component_perfect_scrollbar, {
                    class: _normalizeClass(["base-table__scroll", { 'base-table__scroll-paginated': props.paginated }]),
                    ref: "scroll",
                    options: { wheelPropagation: true, suppressScrollX: false, suppressScrollY: false, useBothWheelAxes: false, minScrollbarLength: 20 },
                    onPsYReachEnd: _cache[0] || (_cache[0] = () => emits('reachEnd'))
                }, {
                    default: _withCtx(() => [
                        (props.columns && props.columns.length > 0)
                            ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
                                (props.rows && props.rows.length > 0 || props.alwaysShowHead)
                                    ? (_openBlock(), _createElementBlock("thead", _hoisted_2, [
                                        _createElementVNode("tr", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (col) => {
                                                return (_openBlock(), _createElementBlock("th", {
                                                    key: col.id,
                                                    style: _normalizeStyle(`width: ${col.width};`),
                                                    class: _normalizeClass([`${col.id}`, col.sort && 'sort']),
                                                    onClick: () => emits('click:th', col)
                                                }, [
                                                    _createElementVNode("span", _hoisted_4, [
                                                        _createElementVNode("span", null, _toDisplayString(col.label), 1),
                                                        (col.sort)
                                                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                                                _createVNode(_component_base_icon, {
                                                                    class: _normalizeClass(["base-table__sort-icon base-table__sort-up", col.sortDir === 'asc' && 'asc']),
                                                                    name: "triangle-up",
                                                                    size: "8"
                                                                }, null, 8, ["class"]),
                                                                _createVNode(_component_base_icon, {
                                                                    class: _normalizeClass(["base-table__sort-icon base-table__sort-down", col.sortDir === 'desc' && 'desc']),
                                                                    name: "triangle-down",
                                                                    size: "8"
                                                                }, null, 8, ["class"])
                                                            ]))
                                                            : _createCommentVNode("", true)
                                                    ])
                                                ], 14, _hoisted_3));
                                            }), 128))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.rows, (row, index) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                            key: row.id,
                                            onClick: () => emits('click:row', row)
                                        }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (col) => {
                                                return (_openBlock(), _createElementBlock("td", {
                                                    key: col.id,
                                                    class: _normalizeClass(`${col.id}`)
                                                }, [
                                                    (_ctx.$slots && _ctx.$slots[col.id])
                                                        ? _renderSlot(_ctx.$slots, col.id, {
                                                            key: 0,
                                                            col: col,
                                                            row: row,
                                                            index: index
                                                        })
                                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                            _createTextVNode(_toDisplayString(row[col.id]), 1)
                                                        ], 64))
                                                ], 2));
                                            }), 128))
                                        ], 8, _hoisted_6));
                                    }), 128))
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 3
                }, 8, ["class"]),
                (_ctx.paginated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _withDirectives(_createVNode(_component_base_pagination, {
                            total: props.total,
                            "total-text": props.totalText,
                            interval: props.interval,
                            "page-pagination": props.pagePagination,
                            onChangePage: changePage,
                            "onUpdate:pagePagination": updatePagePagination
                        }, null, 8, ["total", "total-text", "interval", "page-pagination"]), [
                            [_vShow, props.rows && props.rows.length > 0]
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (props.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_base_loader, { class: "base-table__loader" })
                    ]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
