import _axios from "@/plugins/axios";
export const getUserMe = async () => {
    const { data } = await _axios.get('/users/me');
    return data;
};
export const getUsersList = async (pagination = {}, filters = {}) => {
    const defaultPagination = {
        limit: 20,
        offset: 0
    };
    const defaultFilters = {
        search: null,
        departments: null,
        isActive: false,
        userIds: null
    };
    const { data } = await _axios.post('/users/list', {
        pagination: { ...defaultPagination, ...pagination },
        filters: { ...defaultFilters, ...filters }
    });
    return data;
};
