import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["name", "value", "disabled", "checked"];
const _hoisted_2 = {
    key: 0,
    class: "base-radio__trigger"
};
const _hoisted_3 = {
    key: 1,
    class: "base-radio__text"
};
import { THEME } from "@/components/@types";
import { RADIO_TYPES } from "@/components/@base/@types";
import { ref } from "vue";
const __default__ = { name: 'BaseRadio' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        groupId: { default: '' },
        id: { default: '' },
        modelValue: { default: null },
        label: { default: '' },
        theme: { default: THEME.light },
        type: { default: RADIO_TYPES.default },
        disabled: { type: Boolean, default: false },
        func: { type: [Function, null], default: null },
        anchor: { default: '' }
    },
    emits: ["update:modelValue", "loading", "scrollTo"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const input = ref(null);
        const emits = __emit;
        const update = async (e) => {
            const value = !props.anchor ? e.target?.value : null;
            emits('update:modelValue', typeof props.id === 'number' ? Number(value) : value);
            if (props.func) {
                emits('loading', true);
                await props.func(value);
                emits('loading', false);
            }
        };
        const scrollTo = (id) => {
            if (id) {
                emits('update:modelValue', null);
                emits('scrollTo', id);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass(["base-radio", [props.theme, props.modelValue === props.id && 'checked', `type-${props.type}`, props.disabled && `disabled`]]),
                onClick: _cache[0] || (_cache[0] = ($event) => (scrollTo(props.anchor)))
            }, [
                _createElementVNode("input", {
                    ref_key: "input",
                    ref: input,
                    class: "base-radio__input",
                    type: "radio",
                    name: props.groupId,
                    value: props.id,
                    onInput: update,
                    disabled: props.disabled,
                    checked: props.modelValue === props.id
                }, null, 40, _hoisted_1),
                _renderSlot(_ctx.$slots, "prepend"),
                (props.type !== _unref(RADIO_TYPES).button)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                    : _createCommentVNode("", true),
                (props.label)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.label), 1))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default")
            ], 2));
        };
    }
});
