import _axios from "@/plugins/axios"
import { default as notify, TYPES } from "@/plugins/notify";

export const makeSearch = async (catalogId = 1, text, ids = [], offset = 0, limit = 20) => {
  try {
    const { data } = await _axios.post('/catalog/search', {
      filters: {
      catalogId,
      text,
      ids
    },
    pagination: {
      limit,
      offset
    }
    })

    return data
  } catch(e) {
    notify({
			title: 'Ошибка!',
			text: 'Ошибка при получении данных',
			type: TYPES.error
		});
  }
}

export const getCatalogs = async () => {
  const { data } = await _axios.get('/catalog/list')

  return data
}
