import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "base-input__title"
};
const _hoisted_2 = ["value", "placeholder", "readonly", "disabled", "data-maska", "data-maska-tokens"];
const _hoisted_3 = {
    key: 1,
    class: "base-input__actions"
};
import { BaseIcon } from "@/components/@base";
import { INPUT_MODE } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
import { reactive, watch } from "vue";
import { vMaska } from "maska";
import { useDebounceFn } from '@vueuse/core';
const __default__ = { name: 'BaseInput' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: null },
        placeholder: { default: '' },
        theme: { default: THEME.light },
        required: { type: Boolean, default: false },
        width: { default: '100%' },
        mode: { default: INPUT_MODE.default },
        error: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        search: { type: Boolean, default: false }
    },
    emits: ["update:modelValue", "focus", "confirmEntry", "element"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "2bd6b280": (props.width)
        }));
        const props = __props;
        const data = reactive({
            error: false,
            search: null,
            debounceTime: 1000
        });
        const masks = reactive({
            nums: {
                maska: '#',
                tokens: '#:[0-9]:multiple'
            },
            date: {
                maska: '##.##.####',
                tokens: '#:[0-9]'
            },
            time: {
                maska: '##:##',
                tokens: '#:[0-9]'
            },
            datetime: {
                maska: '##.##.####, ##:##',
                tokens: '#:[0-9]'
            },
            dates: {
                maska: '##.##.#### - ##.##.####',
                tokens: '#:[0-9]'
            },
            float: {
                maska: '#.#',
                tokens: '#:\\d:multiple|#:\\d:multiple'
            }
        });
        const emits = __emit;
        const search = useDebounceFn((event) => {
            update(event);
        }, data.debounceTime);
        const save = (e) => {
            if (props.search) {
                search(e);
            }
            else {
                update(e);
            }
        };
        const update = (e) => {
            let input = e.target;
            let value = input.value.trim();
            if (props.modelValue === value)
                return;
            if (!value)
                return emits('update:modelValue', null);
            if (props.mode === 'nums') {
                if (/\d+/.test(value))
                    return emits('update:modelValue', value);
                if (!/\d+/.test(value))
                    return emits('update:modelValue', null);
            }
            if (props.mode === 'phone') {
                value = value.replace(/[^0-9+]/g, '');
                value = value.replace(/(^\+)|\+/g, (match, p1) => p1 ? '+' : '');
                input.value = value;
                if (/^\+?\d*$/.test(value))
                    return emits('update:modelValue', value);
                return;
            }
            emits('update:modelValue', e.target?.value);
        };
        watch(props, () => {
            if (props.required && !props.modelValue || props.error)
                data.error = true;
            if (props.required && props.modelValue && !props.error || !props.required && !props.error)
                data.error = false;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass(["base-input", [props.theme, data.error && 'error', !props.modelValue && 'empty', props.required && 'required']])
            }, [
                (_ctx.$slots.title)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "title")
                    ]))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default"),
                _withDirectives(_createElementVNode("input", _mergeProps({
                    class: "base-input__field",
                    type: "text",
                    value: props.modelValue,
                    placeholder: props.placeholder,
                    readonly: props.readonly,
                    disabled: props.disabled,
                    onInput: save,
                    onFocus: _cache[0] || (_cache[0] = () => emits('focus')),
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(() => emits('confirmEntry'), ["enter"])),
                    "data-maska": masks[props.mode] && masks[props.mode].maska,
                    "data-maska-tokens": masks[props.mode] && masks[props.mode].tokens
                }, _ctx.$attrs), null, 16, _hoisted_2), [
                    [_unref(vMaska)]
                ]),
                (_ctx.$slots.actions)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _renderSlot(_ctx.$slots, "actions")
                    ]))
                    : _createCommentVNode("", true),
                (props.search)
                    ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                        key: 2,
                        class: "base-input__search",
                        name: "search"
                    }))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
